<template>
  <v-app >
    <Navigation/>
    <v-main class="home companies">
      <v-container class="px-lg-6 px-sm-0 py-lg-8 py-sm-0">
        <router-view></router-view>
        <div class="content" transition="scroll-y-reverse-transition" :style="{borderTopColor:theme.primaryColor} ">
          <div class="content__header">
            <h1 class="content__header--title float-left" >
              Empresas conectadas
            </h1>
          </div>

          <hr class="content__divider mb-5"/>

          <div class="service row">
            <v-row>
                <v-col lg="4 px-3">
                    <InputIcon
                        placeholder="Buscar"
                        icon="true"
                        solid="true"
                    />
                    <ul class="category_list mt-5">
                        <v-subheader class="category_list--header">Categorías</v-subheader>
                        <div
                            v-for="(item, i) in items"
                            :key="i"
                        >
                            <li
                                :class="['category_list__item', selectedItem == i ? 'category_list__item--active': '']"
                                v-ripple="{ center: true }"
                                @click.stop="selectedItem = i"
                            >
                                <a
                                    href="#"
                                    class="category_list__item--title"
                                >
                                    {{item.text}}
                                    <span class="item_title__number">
                                        {{item.number}}
                                    </span>
                                </a>
                            </li>
                            <div class="category_list__divider"></div>
                        </div>
                        <a
                            class="category_list__button col-12"
                            v-ripple="{ center: true }"
                        >
                            Ver todas
                        </a>
                    </ul>
                </v-col>
                <v-col lg="8">
                    <v-row>
                        <div
                          class="col-lg-6" v-for="item in items"
                          :key="item.number"
                        >
                            <Card
                              @click="dialog = true"
                              title="Barracks"
                              category="Tecnología"
                              :theme="theme"
                            />
                        </div>
                    </v-row>
                </v-col>
            </v-row>
          </div>
        </div>
      </v-container>
    </v-main>
  </v-app>
</template>
<script>
import Card from '../components/CompanyCard.vue'
  export default {
    name: 'Company',
    data () {
      return {
        theme:{
          primaryColor: '#6D1AC4',
          service: 'companys'
        },
        dialog: false,
        selectedItem: 3,
        items: [
            { text: 'Marketing', number: 16},
            { text: 'Medicina y salud', number: 12},
            { text: 'Sector automotriz', number: 2},
            { text: 'Tecnología', number: 21},
            { text: 'Organización para eventos', number: 17},
            { text: 'Servicios empresariales', number: 5},
            { text: 'Servicios financieros', number: 1},
            { text: 'Belleza y cuidado personal', number: 4},
        ],
      }
    },
    components: {
        Card
    },
    mounted(){
      this.checkDevice();
    }
  }
</script>
