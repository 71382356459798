<template>
    <div class="company_card">
        <div class="company_card__header">
            <v-list-item
                class="avatar px-0"
            >
                <v-list-item-avatar height="70" width="70">
                    <v-img src="@/assets/img/BARRACKS.png"></v-img>
                </v-list-item-avatar>
                <v-list-item-content style="margin-left: -15px" class="mb-2">
                    <v-list-item-title class="avatar_title" v-html="title"></v-list-item-title>
                    <v-list-item-subtitle class="avatar_text" v-html="category"></v-list-item-subtitle>
                </v-list-item-content>
            </v-list-item>
        </div>
        <div class="company_card__body">
            <p class="company_card__body--text">
                SÉ MEJOR Y LLEVA TU JUEGO AL SIGUIENTE NIVEL - Te acompañamos día a día en tu proceso de mejora con herramientas, métricas y estadísticas diseñadas para ti
            </p>
            <a href="javascript:;" @click="dialog = true" class="company_card__body--link">
                Ver más información
            </a>
        </div>
        <div class="company_card__footer mt-7 mb-2">
            <v-row>
                <v-col lg="6">
                    <v-row>
                        <a href="#" class="ml-3">
                            <GlobeIcon height="20" width="19.729" />
                        </a>
                        <a href="#" class="ml-3">
                            <WhatsAppIcon height="20" width="20" />
                        </a>
                        <a href="#" class="ml-3">
                            <InstagramIcon height="20" width="20" />
                        </a>
                        <a href="#" class="ml-3">
                            <FacebookIcon height="20" width="20" />
                        </a>
                    </v-row>
                </v-col>
                <v-col lg="6">
                    <v-row class="pr-2" style="justify-content: flex-end">
                        <a href="#" @click="dialog = true" class="text-right">
                            <ExpandIcon />
                        </a>
                    </v-row>
                </v-col>
            </v-row>
        </div>
        <v-dialog
            v-model="dialog"
            width="800px"
        >
            <v-card class="dialog_new__detail">
                <v-card-title
                    class="dialog_new__detail--head mb-4 py-1 pt-3" 
                    :style="{backgroundColor: theme.primaryColor}"
                >
                    <a 
                        href="javascript:;"
                        @click.stop="dialog = false"
                    >
                        <CloseIcon/>
                    </a>
                </v-card-title>
                <v-card-text>
                    <v-row>
                        <v-col>
                            <div>
                                <v-list-item
                                    class="avatar px-0"
                                >
                                    <v-list-item-avatar height="100" width="100">
                                        <v-img src="@/assets/img/BARRACKS.png"></v-img>
                                    </v-list-item-avatar>
                                    <v-list-item-content style="margin-left: -10px" class="mb-2">
                                        <v-list-item-title class="avatar_title" v-html="title"></v-list-item-title>
                                        <v-list-item-subtitle class="avatar_text" v-html="category"></v-list-item-subtitle>
                                    </v-list-item-content>
                                </v-list-item>
                            </div>
                            <div class="general_title mb-2">Descripción</div>
                            <div class="general_text">SÉ MEJOR Y LLEVA TU JUEGO AL SIGUIENTE NIVEL - Te acompañamos día a día en tu proceso de mejora con herramientas, métricas y estadísticas diseñadas para ti</div>

                            <div class="general_title mb-2 mt-12">Ubicación</div>
                            <div class="general_text">
                                Av Italia 850 - Providencia
                            </div>
                            <div class="general_text">Oficina 406</div>
                            <div class="mt-10 mb-4">
                                <v-row>
                                    <a href="#" class="ml-3 mr-4">
                                        <GlobeIcon height="40" with="39" />
                                    </a>
                                    <a href="#" class="ml-3 mr-4">
                                        <WhatsAppIcon height="40" with="40" />
                                    </a>
                                    <a href="#" class="ml-3 mr-4">
                                        <InstagramIcon height="40" with="40" />
                                    </a>
                                    <a href="#" class="ml-3 mr-4">
                                        <FacebookIcon height="40" with="40" />
                                    </a>
                                </v-row>
                            </div>
                        </v-col>
                        <v-col>
                            <div class="general_title mb-4 mt-6">Contáctanos</div>
                            <form>
                                <InputIcon placeholder="Ingresa tu nombre" class="mb-3" />
                                <InputIcon placeholder="Ingresa tu correo electrónico" class="mb-3" />
                                <InputIcon placeholder="Empresa" class="mb-3" />
                            </form>
                        </v-col>
                    </v-row>
                </v-card-text>
            </v-card>
        </v-dialog>
    </div>
    
</template>
<script>
export default {
    props:{
        title: String,
        description: String,
        category: String,
        theme: Object
    },
    data(){
        return{
            dialog: false
        }
    }
    
}
</script>